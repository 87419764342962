import React from 'react'

const ProfilePictureWithProgress = ({ showMenu, user }) => (
  <div
    className={`h-11 w-11 hover:bg-grey-F2F2F2 ${
      showMenu && "bg-grey-F2F2F2"
    } rounded-full flex items-center justify-center `}
  >
    <div className="relative " style={{ width: 42.1, height: 42.1 }}>
      <img
        className="absolute top-0 left-0 w-8 h-8 rounded-full "
        src={user.profilePicture? user.profilePicture:"/images/userDefaultIcon.png"}
        alt={user?.firstName || ""}
        style={{
          width: "70%",
          height: "70%",
          borderRadius: "50%",
          transform: "translate(15%, 15%)",
        }}
      />
    </div>
  </div>
);

export default ProfilePictureWithProgress
