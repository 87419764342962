import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(
  ({
    className,
    textColor = "#fff",
    backgroundColor = "rgb(57,57,57,0.9)",
    ...props
  }) => <Tooltip {...props} classes={{ popper: className }} />
)(({ theme, backgroundColor, textColor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: backgroundColor,
    color: textColor,
    boxShadow: theme.shadows[1],
    "& .MuiTooltip-arrow": {
      color: backgroundColor,
    },
  },
}));

export default LightTooltip;
