import React, { useEffect } from "react";
import { history } from "../../managers/history";
import { useSelector } from "react-redux";

const SidebarMobile = ({ setSidebar, sidebar }) => {
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const { accountCompletion = {} } = user;

    if (accountCompletion?.isLoading) return;
    // eslint-disable-next-line
  }, [user.accountCompletion]);

  return (
    <div>
      <div
        className="fixed top-0 left-0 w-screen h-full z-40"
        onClick={() => setSidebar(false)}
      ></div>
      <div className="shadow-sidebarMobile z-50 w-full overflow-y-auto flex justify-between flex-col fixed left-0 top-16 bg-white dark:bg-black-111111 small:hidden">
        <div className="">
          <div className="mt-5 pl-4 pr-4 flex flex-col tab">
            {!user.accountCompletion?.isKnowledgeBaseExist &&
              !user?.accountCompletion?.isLoading && (
                <MenuList
                  imageInactive={"/images/guide-logo.svg"}
                  link={"/knowledge-base/data-import"}
                  name={"Get Started"}
                  setSidebar={setSidebar}
                  sidebar={sidebar}
                  imageActive={"/images/guide-logo-active.svg"}
                  user={user}
                />
              )}
            <MenuList
              imageActive={"/images/knowledge-logo-blue.svg"}
              imageInactive={"/images/knowledge-logo.svg"}
              link={"/knowledge-base"}
              name={"Knowledge Base"}
              setSidebar={setSidebar}
              sidebar={sidebar}
              user={user}
            />
            <MenuList
              imageActive={"/images/apps-logo-active.svg"}
              imageInactive={"/images/apps-logo.svg"}
              link={"/apps"}
              name={"Apps"}
              setSidebar={setSidebar}
              sidebar={sidebar}
              user={user}
            />
            <MenuList
              imageActive={"/images/flow-icon-blue.svg"}
              imageInactive={"/images/flow-icon.svg"}
              link={"/flows"}
              name={"Flows"}
              setSidebar={setSidebar}
              sidebar={sidebar}
              user={user}
            />
            <MenuList
              imageActive={"/images/agents-active.svg"}
              imageInactive={"/images/agents.svg"}
              link={"/agents"}
              name={"Agents"}
              setSidebar={setSidebar}
              sidebar={sidebar}
              user={user}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const MenuList = ({
  link,
  imageInactive,
  imageActive,
  setSidebar,
  alt,
  sidebar,
  name,
  altRoute,
  user,
}) => {
  const checkIfSelected = (page) => {
    if (!user?.accountCompletion?.isKnowledgeBaseExist) {
      if (
        window.location.pathname === "/apps/create" ||
        window.location.pathname === "/apps/configure" ||
        window.location.pathname === "/knowledge-base/data-import"
      ) {
        return window.location.pathname.includes(page);
      } else {
        return window.location.pathname === page;
      }
    } else {
      return (
        window.location.pathname.includes(page) ||
        (page === "/agents" && window.location.pathname.includes("/agent"))
      );
    }
  };
  return (
    <div
      className={`flex items-center mb-1  cursor-pointer h-11 px-4 py-3 rounded-md
      ${
        checkIfSelected(link) || checkIfSelected(altRoute)
          ? "bg-blue-130 bg-opacity-10 text-blue-130  font-PoppinsSemiBold"
          : "hover:bg-opacity-10 text-black-10"
      }`}
      onClick={() => {
        history.push(link);
        setSidebar(!sidebar);
      }}
    >
      {checkIfSelected(link) ? (
        <img src={imageActive} alt={alt} className="mr-2 h-4.5 w-4.5" />
      ) : (
        <img src={imageInactive} alt={alt} className="mr-2 h-4.5 w-4.5" />
      )}
      <span className=" text-ft3 font-PoppinsRegular">{name}</span>
    </div>
  );
};

export default SidebarMobile;
