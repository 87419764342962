import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";
import utility from "../utility";

const { FLOW_TOKEN, AUTH0_ID_TOKEN } = keyTypeConstants;
const {
  METHOD_TYPE: { POST },
  API_END_POINT: {
    AUTH_TOKEN,
    APP_CONNECTIONS,
    GET_CLIENT_ID,
    ACTIVE_PIECE,
    UPDATE_FLOW,
  },
} = httpConstants;

export default class ZBrainFlowService {
  baseUrl = process.env.REACT_APP_TOOL_MICROSERVICE;

  async getHeaders() {
    const tenant = utility.getDataFromLocalStorage("user");

    if (!tenant) {
      throw new Error("ZbrainFlowService: Tenant not found");
    }
    const token = await this.getToken({
      tenantId: tenant.tenantId,
    });
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token.token}`,
    };
  }

  getToken = async (data) => {
    try {
      // NOTE: Creating a key using tenantId to prevent flow access token mismatch issues when users switch tenants.
      const cookieKey = `${FLOW_TOKEN}:${data.tenantId}`;

      const token = sessionManager.getDataFromCookies(cookieKey);

      if (token) return token;

      const platformToken = sessionManager.getDataFromCookies(AUTH0_ID_TOKEN);

      const response = await fetch(this.baseUrl + `${AUTH_TOKEN}`, {
        method: POST,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${platformToken}`,
        },
        body: JSON.stringify({
          ...data,
          token: platformToken,
          tenantId: data.tenantId,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const res = await response.json();

      sessionManager.setDataInCookies(res, cookieKey, 1);
      return res;
    } catch (error) {
      console.error("Error fetching authToken:", error);
      throw error;
    }
  };

  getConnections = async (data) => {
    const query = new URLSearchParams(data).toString();
    const headers = await this.getHeaders();

    try {
      const response = await fetch(
        `${this.baseUrl}${APP_CONNECTIONS}?${query}`,
        { method: "GET", headers }
      );
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error fetching tool connections:", error);
      throw error;
    }
  };

  deleteConnection = async (connectionId) => {
    const headers = await this.getHeaders();

    try {
      const response = await fetch(
        this.baseUrl + `${APP_CONNECTIONS}/${connectionId}`,
        { method: "DELETE", headers }
      );
      return response;
    } catch (error) {
      console.error("Error fetching tool connections:", error);
      throw error;
    }
  };

  addConnection = async (data) => {
    const headers = await this.getHeaders();
    try {
      const response = await fetch(`${this.baseUrl}${APP_CONNECTIONS}`, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error adding connection:", error);
      throw error;
    }
  };

  getClientIds = async () => {
    const headers = await this.getHeaders();
    try {
      const response = await fetch(`${this.baseUrl}${GET_CLIENT_ID}`, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error adding connection:", error);
      throw error;
    }
  };

  getPieceUrl = async (pieceName) => {
    const path = `/${pieceName}`;
    const headers = await this.getHeaders();
    try {
      const response = await fetch(`${this.baseUrl}${ACTIVE_PIECE}${path}`, {
        method: "GET",
        headers,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error adding connection:", error);
      throw error;
    }
  };
  updateFlow = async (data, flowId) => {
    const headers = await this.getHeaders();
    try {
      const response = await fetch(`${this.baseUrl}${UPDATE_FLOW}/${flowId}`, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error adding connection:", error);
      throw error;
    }
  };

  getFlow = async (flowId) => {
    const headers = await this.getHeaders();
    try {
      const response = await fetch(`${this.baseUrl}${UPDATE_FLOW}/${flowId}`, {
        method: "GET",
        headers        
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error adding connection:", error);
      throw error;
    }
  };
}
