import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-circular-progressbar/dist/styles.css";
import SidebarMobile from "../sidebar/sidebarMobile.js";
import { checkIsSidebarOpen } from "../../reducers/manageSidebar.js";
import UserService from "../../services/userService.js";
import {
  verifiedEmailOpen,
  isUpgradePlanOpen,
} from "../../reducers/verifyEmail.js";
import { history } from "../../managers/history.js";
import SetNewPassword from "../modal/setNewPasswordPopUp.js";
import { keyTypeConstants, toastType } from "../../constants/index.js";
import ShowToast from "../../common/components/showToast.js";
import PaymentService from "../../services/paymentService.js";
import utility from "../../utility/index.js";
import RippleButton from "../../common/rippleButton.js";
import SidebarNew from "../sidebar/sidebarNew.js";
import { setRemainingCredits } from "../../reducers/credits.js";
import { sessionManager } from "../../managers/sessionManager.js";
import LightTooltip from "../../common/LightTooltip.js";
import { Auth0Service } from "../../services/index.js";
import { FreeTrialMessage } from "./freeTrial.js";
import ProfileDropDown from "./profileDropDown.js";
import ProfilePictureWithProgress from "./profilePictureWIthProgress.js";
import VerifyEmailMessage from "./verifyEmailMessage.js";
const Header = ({
  setTenantData,
  isTenantData = false,
  isNavigatedFromDots = false,
}) => {
  const user = useSelector((state) => state.user);
  const dropSettingRef = useRef(null);
  const dotRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const [message, setMessage] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isCompLoading, setIsCompLoading] = useState(false);
  const dispatch = useDispatch();
  const isSidebarActive = useSelector((state) => state?.sidebar?.isSideBarOpen);
  const isVerifiedOpen = useSelector((state) => state?.verified?.isVerified);
  const isTrailOpen = useSelector((state) => state?.verified?.isPlanUpgraded);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [userSubscription, setUserSubscription] = useState({});
  const [userLoading, setUserLoading] = useState(true);
  const profileButtonRef = useRef(null);
  const dropdownRef = useRef(null);
  const availaibleCredits = useSelector(
    (state) => state.credits.remainingCredits
  );
  const isPublicAgent = useSelector(
    (reduxState) => reduxState.worker?.isPublicAgent
  );
  const isOwner = utility.isRoleOwner(user);
  const adminView = process.env.REACT_APP_ADMIN_VIEW === "true";
  const ownerTenantId = useSelector((state) => state.user.ownerTenantId);
  const remainingCredits = useMemo(() => {
    return (
      (userSubscription?.featureBalances?.credits || 0) +
        (userSubscription?.addOns?.credits || 0) || 0
    );
  }, [userSubscription]);
  if (remainingCredits > 0) {
    let newCredits = remainingCredits;
    if (newCredits) {
      dispatch(setRemainingCredits(newCredits));
    }
  }
  const previousCredits = JSON.parse(localStorage.getItem("previousCredits"));

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    setShowMenu(false);
  };

  const { APP_STATE } = keyTypeConstants;
  const userData = JSON.parse(localStorage.getItem("user"));
  const userRole = userData?.role;
  const showResetPwd = user?.userId && user?.userId?.startsWith("auth");

  const toggleSideBar = () => {
    dispatch(checkIsSidebarOpen(!isSidebarActive));
  };

  const handleSetClose = () => {
    dispatch(verifiedEmailOpen(false));
  };

  const handleTrailClose = () => {
    dispatch(isUpgradePlanOpen(false));
  };

  const logOut = () => {
    sessionManager.removeAllCookies();
    dispatch({
      type: "REMOVE_USER",
      payload: {},
    });
    dispatch(setRemainingCredits(0));
    dispatch(verifiedEmailOpen(true));
    sessionStorage.clear();
    localStorage.clear();
    window.$crisp.push(["do", "session:reset"]);
    history.push("/");
    const auth0Service = new Auth0Service();
    auth0Service.logoutAuth0();
  };

  const handleVerifyEmail = async () => {
    try {
      const request = { userId: user?.userId, tenantId: user?.tenantId };
      setLoading(true);
      const userService = new UserService();
      await userService.sendVerifyEmail(request);
      setMessage(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const [currentStep, setCurrentStep] = useState(null);

  useEffect(() => {
    const { accountCompletion = {} } = user;
    const {
      isLoading,
      isBotPerformanceExist,
      isGoldenQuestionExist,
      isAppTested,
      isAppExist,
      isKnowledgeBaseExist,
    } = accountCompletion;

    if (isLoading) return;

    if (isBotPerformanceExist) {
      setCurrentStep(null);
    } else if (isGoldenQuestionExist) {
      setCurrentStep(4);
    } else if (isAppTested) {
      setCurrentStep(3);
    } else if (isAppExist) {
      setCurrentStep(2);
    } else if (isKnowledgeBaseExist) {
      setCurrentStep(1);
    } else {
      setCurrentStep(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.accountCompletion]);

  const onTestApp = () => {
    if (user?.accountCompletion?.app?._id) {
      if (user?.accountCompletion?.app.configuration.type === "PRIVATE") {
        history.push(
          `/user/sign-in?appId=${user.accountCompletion.app._id}&tenantId=${user?.tenantId}`
        );
      } else {
        history.push(
          `/chat/${user.accountCompletion.app._id}?tenantId=${user?.tenantId}`
        );
      }
    }
  };

  const onAddTestQuestion = () => {
    if (user?.accountCompletion?.app?._id) {
      localStorage.setItem(
        APP_STATE,
        JSON.stringify({ appState: "test-questions" })
      );
      history.push(`/apps/manage/${user.accountCompletion.app._id}`);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleClickOutside = (event) => {
    if (
      dropSettingRef.current &&
      !dropSettingRef.current.contains(event.target) &&
      dotRef.current &&
      !dotRef.current.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen, dotRef, dropSettingRef]);

  useEffect(() => {
    if (ownerTenantId) {
      (async () => {
        setUserLoading(true);
        try {
          const currentSubscription =
            await new PaymentService().getSubscription({
              tenantId: user?.tenantId,
            });
          setUserSubscription(currentSubscription);
          setUserLoading(false);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [ownerTenantId]);
  useEffect(() => {
    if (
      !userLoading &&
      remainingCredits < 1000 &&
      remainingCredits !== previousCredits
    ) {
      ShowToast({
        message: "Your credit balance is low.",
        type: toastType.SUCCESS,
        duration: "",
        isWarning: true,
      });
      localStorage.setItem("previousCredits", JSON.stringify(remainingCredits));
    }
  }, [remainingCredits, previousCredits, userLoading]);

  const checkingKnowledgeBase = () => {
    const route = user?.accountCompletion?.isKnowledgeBaseExist
      ? "/knowledge-base"
      : "/knowledge-base/data-import";
    history.push(route);
  };

  const parseInvitedData = async () => {
    try {
      setIsCompLoading(true);
      const queryParams = `${user?.adminTenantId}?populate=invitedBy.tenantId`;
      const tenant = await new UserService().getTenantDetails(queryParams);
      if (isTenantData) {
        setTenantData(tenant);
      }
      if (!tenant?.invitedBy) tenant.invitedBy = [];

      const accounts = {
        [user?.adminTenantId]: {
          role: "OWNER",
          tenantId: user?.adminTenantId,
          tenantName: user?.name,
          picture: user?.profilePicture,
          email: user?.email,
          companyName: user?.companyName,
        },
      };

      // eslint-disable-next-line no-unused-vars
      for (const acc of tenant.invitedBy) {
        if (acc?.tenant && acc?.tenantId) {
          if (accounts[acc.tenantId]) continue;
          accounts[acc.tenantId] = {
            role: acc?.role || "USER",
            tenantId: acc.tenantId,
            tenantName: acc.tenant?.tenantName || acc.tenant?.firstName,
            picture: acc.tenant?.profilePicture,
            email: acc.tenant?.email,
            status: acc.tenant?.status,
            companyName: acc.tenant?.companyName,
          };

          const tenantResponse = await new UserService().getTenantDetails(
            acc.tenantId
          );
          if (tenantResponse) {
            dispatch({
              type: "UPDATE_GUIDED_TOUR",
              payload: {
                userWalkthroughTutorials:
                  tenantResponse?.userWalkthroughTutorials,
              },
            });
          }
        }
      }

      setIsCompLoading(false);
      setCompanyData(Object.values(accounts));
    } catch (error) {
      console.error("Parse Invited Data", error);
    }
  };
  useEffect(() => {
    setCompanyData((prev) =>
      prev.map((c) =>
        c.role === "OWNER"
          ? {
              role: "OWNER",
              tenantId: user?.adminTenantId,
              tenantName: user?.name,
              picture: user?.profilePicture,
              email: user?.email,
              companyName: user?.companyName,
            }
          : c
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.name, user.companyName]);

  useEffect(() => {
    const ownerTenantId = companyData?.find(
      (item) => item.role === "OWNER"
    )?.tenantId;
    if (ownerTenantId) {
      dispatch({
        type: "UPDATE_OWNER_TENANT_ID",
        payload: ownerTenantId,
      });
    }
  }, [companyData, dispatch]);

  useEffect(() => {
    parseInvitedData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (companyData.length > 0) {
      const currentTenantExists = companyData.some(
        (company) => company.tenantId === user.tenantId
      );
      if (!currentTenantExists) {
        const ownerProfile = companyData.find(
          (company) => company.role === "OWNER"
        );
        if (ownerProfile) {
          dispatch({
            type: "UPDATE_USER",
            payload: {
              tenantId: ownerProfile.tenantId,
              role: ownerProfile.role,
              name: ownerProfile.tenantName,
              email: ownerProfile.email,
              companyName: ownerProfile.companyName,
              profilePicture: ownerProfile.picture,
            },
          });
        }
      }
    }
  }, [companyData, user.tenantId, dispatch]);
  const onCreditTabClick = async () => {
    if (user.role === "OWNER") {
      if (userSubscription?.responseCode === 404) {
        history.push("/pricing/overview/plan");
      } else {
        history.push("/pricing/credit-usage");
      }
    }
  };

  let onClickAction = !isLoading ? handleVerifyEmail : "";

  const getSelectedUser = () => {
    const curtUser = companyData?.find((u) => user?.tenantId === u?.tenantId);
    if (curtUser) {
      return curtUser;
    } else {
      return {
        ...user,
        tenantName: user.firstName,
        picture: user.profilePicture,
      };
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileButtonRef.current &&
        !profileButtonRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowMenu, profileButtonRef, dropdownRef]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data?.type === "iframeClicked") {
        setShowMenu(false);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  return (
    <div className="top-0 w-full z-299">
      {Boolean(isVerifiedOpen && !user.email_verified) && (
        <VerifyEmailMessage
          verifyMessage={message}
          onClickVerifyAction={onClickAction}
          isHandleSetClose={handleSetClose}
        />
      )}
      {Boolean(
        isTrailOpen &&
          user.email_verified &&
          userSubscription?.planDetails?.planType === "Free"
      ) && (
        <FreeTrialMessage
          handleSetClose={handleSetClose}
          isHandleTrailClose={handleTrailClose}
        />
      )}

      <div
        className={`px-5 flex ${
          isOwner && adminView ? "flex-wrap" : "justify-between"
        } mobile:flex-wrap items-center shadow-headerShadow border-b z-50 bg-white border-grey-10 h-15 ${
          isSidebarActive ? "backdrop-filter bg-grey-F3F3F3 opacity-60" : ""
        }`}
      >
        <div className="flex items-center">
          <img
            className="w-4 h-11px mr-5 lg:hidden"
            src="/images/menu-icon.svg"
            alt="menu"
            onClick={toggleSideBar}
          />
          <div
            onClick={checkingKnowledgeBase}
            className="flex items-center justify-center"
          >
            <img
              className="cursor-pointer "
              alt=""
              src="/images/zbrain-new-logo.svg"
            />
          </div>
        </div>
        {(isOwner && adminView) || isPublicAgent ? null : <SidebarNew />}
        {user.isLoggedIn && !isPublicAgent ? (
          <div className="flex items-center gap-3 mobile:gap-1 ml-auto justify-end w-400px">
            <div className="relative ">
              {userLoading ? (
                <div className="h-10 min-w-400px"></div>
              ) : (
                <RippleButton
                  type="submit"
                  className="flex items-center justify-center h-9 gap-1 min-w-16 mobile:w-auto px-4 py-1 text-white bg-white border rounded-full font-Poppins border-grey-10 hover:bg-grey-70"
                  onClick={() => onCreditTabClick()}
                >
                  <span className="font-semibold text-black-10 text-ft2 mobile:text-ft1 w-200">
                    {Math.trunc(availaibleCredits)}
                  </span>
                  <span className="font-normal text-grey-626262 text-ft2 mobile:text-ft1">
                    Credits
                  </span>
                </RippleButton>
              )}
              {!userLoading && availaibleCredits < 1000 && (
                <LightTooltip title={"Low Credits"} arrow placement="right">
                  <img
                    src="/images/warn.png"
                    alt="warn"
                    className="absolute top-0 right-0"
                  />
                </LightTooltip>
              )}
            </div>
            {user.role === "OWNER" && (
              <div
                className={`relative mx-1 cursor-pointer mobile:hidden hover:bg-grey-EEEEEE ${
                  (isNavigatedFromDots || isDropdownOpen) &&
                  "border-2 border-blue-130 bg-grey-EEEEEE"
                } rounded-1 flex justify-center items-center h-6.5 w-6.5`}
                ref={dotRef}
                onClick={toggleDropdown}
              >
                <img
                  src="/images/three-dots-icon.svg"
                  alt="three dots"
                  className="cursor-pointer"
                />
              </div>
            )}

            {isDropdownOpen && (
              <div
                className="absolute flex flex-col right-30 top-12 mt-2 w-43 bg-white z-50 shadow-optionsShadow rounded-2.5 overflow-hidden"
                ref={dropSettingRef}
              >
                {(userRole === "OWNER" || userRole === "ADMIN") && (
                  <span
                    onClick={() => history.push("/manage-user")}
                    className="block px-3 py-3 font-PoppinsRegular cursor-pointer text-ft2 text-black-10 hover:bg-grey-70 hover:rounded-2.5"
                  >
                    Settings
                  </span>
                )}

                {(userRole === "OWNER" || userRole === "ADMIN") && (
                  <span
                    onClick={() => history.push("/pricing/overview")}
                    className="block px-3 py-3 font-PoppinsRegular cursor-pointer text-ft2 text-black-10 hover:bg-grey-70 hover:rounded-2.5"
                  >
                    Billing
                  </span>
                )}

                {showResetPwd && userRole === "OWNER" && (
                  <span
                    onClick={handleOpenModal}
                    className="block px-3 py-3 font-PoppinsRegular cursor-pointer text-ft2 text-black-10 hover:bg-grey-70 hover:rounded-2.5"
                  >
                    Change Password
                  </span>
                )}
              </div>
            )}

            <h2 className="font-PoppinsRegular text-ft2 mobile:hidden text-black-10">
              {user?.name}
            </h2>

            <button
              ref={profileButtonRef}
              className="flex items-center justify-end gap-3"
              onClick={() => setShowMenu((prev) => !prev)}
            >
              <ProfilePictureWithProgress
                user={user}
                percentage={user.accountCompletion.percentage}
                showMenu={showMenu}
                selectedUser={getSelectedUser()}
              />
            </button>
            {showMenu && (
              <ProfileDropDown
                logOut={logOut}
                showResetPwd={user?.userId && user?.userId?.startsWith("auth")}
                onClickOutside={setShowMenu}
                setShowMenu={setShowMenu}
                companyData={companyData}
                setIsModalOpen={setIsModalOpen}
                isCompLoading={isCompLoading}
                role={user?.role}
                userData={userData}
                currentStep={currentStep}
                onAddTestQuestion={onAddTestQuestion}
                onTestApp={onTestApp}
                selectedUser={getSelectedUser()}
                dropdownRef={dropdownRef}
              />
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      {isModalOpen && (
        <SetNewPassword closeModal={() => setIsModalOpen(false)} />
      )}
      {isSidebarActive && (
        <SidebarMobile setSidebar={toggleSideBar} sidebar={isSidebarActive} />
      )}
    </div>
  );
};

export default Header;
