import React from "react";
import { history } from "../../managers/history";
import { RxCross1 } from "react-icons/rx";

export const FreeTrialMessage = ({ isHandleTrailClose }) => {
  return (
    <div className="bg-blue-130 py-1.5 flex w-full z-20 justify-between px-3">
      <div className="flex justify-center gap-1 mx-auto">
        <span
          onClick={() => history.push("/pricing/overview/plan")}
          className="text-white cursor-pointer font-PoppinsRegular text-ft1 lg:text-ft2"
        >
          Upgrade Your ZBrain Experience with 15 Days Free Trial of Our Premium
          Plan! Upgrade Now
        </span>
      </div>
      <div onClick={isHandleTrailClose}>
        <RxCross1 className="text-white cursor-pointer" />
      </div>
    </div>
  );
};
