import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../managers/history';
import ShowToast from '../../common/components/showToast';
import { successMessages, toastType } from '../../constants';
import utility from "../../utility";
const ProfileDropDown = ({
    logOut,
    companyData,
    isCompLoading,
    userData,
    selectedUser,
    dropdownRef,
}) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const switchCompany = async (data) => {
      if (data.status === "DISABLED" || userData?.tenantId === data.tenantId) {
        return;
      }
      dispatch({
        type: "UPDATE_USER",
        payload: {
          tenantId: data.tenantId,
          role: data.role,
          selectedUser: data,
        },
      });
      history.push("/");
      ShowToast({
        message: successMessages.COMPANY_SWITCHED,
        type: toastType.SUCCESS,
      });
    };
    
    return (
      <div
        ref={dropdownRef}
        className={`absolute top-15 right-4 font-normal bg-white w-273px h-auto items-center gap-3 shadow-popupShadow px-auto rounded-md  py-2 pb-0 z-10 text-ft13 font-PoppinsMedium mobile:w-full mobile:right-0 mobile:top-16`}
      >
        <div className="flex flex-col ">
          <div className="flex flex-col border-b border-grey-10 border-opacity-25 items-center justify-center mt-6 pb-4.5">
            <div>
              <img
                className=" w-10.5 h-10.5 rounded-full"
                src={user.profilePicture? user.profilePicture:"/images/userDefaultIcon.png"}
                alt=""
              />
            </div>
            <div className="font-InterMedium text-ft2 text-black-10 mt-1.5">
              {user?.firstName}
            </div>
            <div className="font-InterRegular text-ft1 text-grey-A1A1A1">
              {user?.email
                ? utility.companyNameShortner(user?.email).toLowerCase()
                : "-"}
            </div>
          </div>
          {isCompLoading ? (
            <div className="grid w-full bg-transparent place-content-center">
              <div className="flex items-center justify-center">
                <div className="loader-5 center">
                  <span></span>
                </div>
              </div>
            </div>
          ) : (
            companyData
              .filter((item) => item?.tenantName && item?.status !== "DISABLED")
              .map((item, index) => (
                <div
                  key={index}
                  className="flex justify-between  gap-2 px-4 text-black-10 items-center border-b border-grey-10 border-opacity-25 text-ft2 pt-1.5 rounded truncate pb-3 cursor-pointer hover:bg-grey-70"
                  onClick={() => switchCompany(item)}
                >
                  <div className="flex items-center gap-3.5">
                    <img
                      src={
                        item?.picture
                          ? item?.picture
                          : "/images/userDefaultIcon.png"
                      }
                      alt=""
                      className="rounded-full w-7.5 h-7.5"
                    />
                    <div>
                      <span className="font-medium truncate font-PoppinsRegular text-ft2">
                        {item?.tenantName}
                      </span>
                    </div>
                  </div>
                  {selectedUser?.tenantId === item?.tenantId && (
                    <div>
                      <img src="/images/check.svg" alt=""></img>
                    </div>
                  )}
                </div>
              ))
          )}
        </div>
        <div
          onClick={logOut}
          className="flex gap-4.5 px-3 py-17px text-ft2 font-normal font-Poppins cursor-pointer text-black-10 hover:bg-grey-70 border-t border-grey-10 border-opacity-25"
        >
          <img
            className="w-4.5 h-4.5 ml-2.5"
            src="/images/logout-icon.svg"
            alt=""
          />
          <p className="flex items-center">Sign Out </p>
        </div>
      </div>
    );
  };

  export default ProfileDropDown  
