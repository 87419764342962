import { httpService } from "../utility/httpService";
import { httpConstants, keyTypeConstants } from "../constants";
import { sessionManager } from "../managers/sessionManager";

const {
  METHOD_TYPE: { POST, GET, DELETE },
  API_END_POINT: { AGENT, AGENTS, WORKFLOW, WORKFLOWS, GENERATE_FLOW },
} = httpConstants;
const { AUTH0_ID_TOKEN } = keyTypeConstants;
export function getHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      AUTH0_ID_TOKEN
    )}`,
  };
}

export function getHeaderForUploadFile() {
  return {
    Authorization: `Bearer ${sessionManager.getDataFromCookies(
      AUTH0_ID_TOKEN
    )}`,
  };
}

export default class AgentService {
  baseUrl = process.env.REACT_APP_WEB_AGENT_MICROSERVICE_URL;
  flowURL = process.env.REACT_APP_FLOW_MICROSERVICE_URL;

  getAgents = async (data) => {
    const query = !!data
      ? `${AGENTS}?${new URLSearchParams(data).toString()}`
      : AGENTS;
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + query, {}, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  createAgents(requestData) {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + AGENT, requestData, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  deleteAgent = async (agentId, queryData) => {
    const query = new URLSearchParams(queryData).toString();
    return new Promise((resolve, reject) => {
      httpService(
        DELETE,
        this.baseUrl + `${AGENT}/${agentId}?${query}`,
        {},
        getHeaders()
      )
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };

  createWorkflow(requestData) {
    return new Promise((resolve, reject) => {
      httpService(POST, this.baseUrl + WORKFLOW, requestData, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  }

  getWorkflow = async (data) => {
    const query = !!data
      ? `${WORKFLOWS}?${new URLSearchParams(data).toString()}`
      : AGENTS;
    return new Promise((resolve, reject) => {
      httpService(GET, this.baseUrl + query, {}, getHeaders())
        .then((res) => {
          if (!res.success || res.responseCode !== 200) return reject(res);
          return resolve(res?.responseData);
        })
        .catch(reject);
    });
  };
}
