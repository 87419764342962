import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { history } from "../../managers/history";

const SidebarNew = () => {
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const { accountCompletion = {} } = user;

    if (accountCompletion?.isLoading) return;
    // eslint-disable-next-line
  }, [user.accountCompletion]);

  return (
    <div
      className={
        "lg:ml-15per 10per text-ft15 border-grey-10 font-PoppinsRegular mobile:hidden tab:hidden flex flex-grow gap-2 justify-center items-center mx-auto"
      }
    >
      <MenuItems
        name="Knowledge Base"
        route="/knowledge-base"
        logo="/images/KB-Default.svg"
        activeLogo="/images/kb-icon-new.svg"
        user={user}
      />
      <MenuItems
        name="Apps"
        route="/apps"
        logo="/images/apps-logo.svg"
        activeLogo="/images/apps-logo-active.svg"
        user={user}
      />
      <MenuItems
        name="Flows"
        route="/flows"
        logo="/images/flow-icon.svg"
        activeLogo="/images/flow-icon-blue.svg"
        user={user}
      />
      <MenuItems
        name="Agents"
        route="/agents"
        logo="/images/agents.svg"
        activeLogo="/images/agents-active.svg"
        user={user}
      />

    </div>
  );
};

const MenuItems = ({
  name,
  route,
  altRoute,
  logo,
  activeLogo,
  hideName,
  user,
}) => {
  const checkIfSelected = (page) => {
    if (!user?.accountCompletion?.isKnowledgeBaseExist) {
      if (
        window.location.pathname === "/apps/create" ||
        window.location.pathname === "/apps/configure" ||
        window.location.pathname === "/knowledge-base/data-import" ||
        window.location.pathname === "/agents/new" ||
        window.location.pathname === "/builder"
      ) {
        return window.location.pathname.includes(page);
      } else {
        return window.location.pathname === page;
      }
    } else {
      return (
        window.location.pathname.includes(page) ||
        (page === "/agents" && window.location.pathname.includes("/agent"))
      );
    }
  };

  if (hideName) {
    return (
      <div
        className={`flex items-center cursor-pointer h-10 w-14 rounded-md text-ft15 text-black-10
          ${
            checkIfSelected(route) || checkIfSelected(altRoute)
              ? " bg-blue-5E66D61A bg-opacity-10 text-blue-1A55F9 font-PoppinsMedium text-ft3"
              : "hover:bg-opacity-10 font-PoppinsRegular text-ft3"
          }`}
        onClick={() => history.push(route)}
      >
        <img
          className=" w-5"
          alt=""
          src={checkIfSelected(route) ? activeLogo : logo}
        />
      </div>
    );
  }

  return (
    <div
      className={`flex items-center cursor-pointer h-10 py-4 px-2 rounded-md text-black-10
          ${
            checkIfSelected(route) || checkIfSelected(altRoute)
              ? " bg-blue-5E66D61A bg-opacity-10 text-blue-1A55F9 font-PoppinsMedium text-ft3"
              : " font-PoppinsRegular hover:bg-grey-150 text-ft3"
          }`}
      onClick={() => history.push(route)}
    >
      <img
        className="mr-3 w-5 h-5"
        alt=""
        src={checkIfSelected(route) ? activeLogo : logo}
      />
      <div>{name}</div>
    </div>
  );
};

export default SidebarNew;
