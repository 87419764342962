import React from 'react'
import { RxCross1 } from 'react-icons/rx';
import { useDispatch } from 'react-redux';
import { verifiedEmailOpen } from '../../reducers/verifyEmail';

const VerifyEmailMessage = ({
    verifyMessage,
    onClickVerifyAction,
    isHandleSetClose,
  }) => {
        const dispatch = useDispatch();
    const handleClose = () => {
      dispatch(verifiedEmailOpen(false));
      isHandleSetClose();
    };
    return (
      <div className="bg-blue-130 py-1.5 flex w-full z-20 justify-between px-3">
        {!verifyMessage ? (
          <div className="flex justify-center gap-1 mx-auto">
            <p className="text-white font-PoppinsRegular text-ft1 lg:text-ft2">
              We have sent an email to verify your email
            </p>
            <p
              onClick={onClickVerifyAction}
              className="text-white underline cursor-pointer font-PoppinsRegular text-ft1 lg:text-ft2"
            >
              Send Again.
            </p>
          </div>
        ) : (
          <div className="flex justify-center gap-1 mx-auto">
            <p className="text-white font-PoppinsRegular text-ft2">
              Verify link sent to your mail.
            </p>
          </div>
        )}
        <div onClick={handleClose}>
          <RxCross1 className="text-white cursor-pointer" />
        </div>
      </div>
    );
  };

export default VerifyEmailMessage
