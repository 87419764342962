import React, { useEffect, useState } from "react";
import { BiLoaderAlt } from "react-icons/bi";
import { toastType } from "../../constants";
import ShowToast from "./showToast";
import { useSelector } from "react-redux";
import { history } from "../../managers/history";
import Header from "../../modules/header/header";
import PaymentService from "../../services/paymentService";

function CancelPlan() {

  const [cancelText, setCancelText] = useState("");
  const [cancelReason, setCancelReason] = useState([]);
  const [isCancelationLoading, setIsCancelationLoading] = useState(false);
  const user = useSelector((state) => state.user);


  const cancelSubscriptionPlan = async () => {
    setIsCancelationLoading(true);
    let request = {
      tenantId: user?.tenantId,
      cancelAtCycleEnd: true,
    };
    try {
      await new PaymentService().cancelSubscription(request);
      ShowToast({
        message: "Plan canceled successfully",
        type: toastType.SUCCESS,
      });
      setIsCancelationLoading(false);
      window.pop();
    } catch (error) {
      ShowToast({
        message: "Failed to cancel the plan",
        type: toastType.ERROR,
      });
      setIsCancelationLoading(false);
      console.log("error: ", error);
    }
  };

  const [componentHeight, setComponentHeight] = useState(window.innerHeight);

  const updateComponentHeight = () => {
    setComponentHeight(window.innerHeight);
  };

  useEffect(() => {
    updateComponentHeight();
    window.addEventListener("resize", updateComponentHeight);
    return () => {
      window.removeEventListener("resize", updateComponentHeight);
    };
  }, []);

  const handleReasonChange = (e) => {
    const { value, checked } = e.target;
    setCancelReason((prev) =>
      checked ? [...prev, value] : prev.filter((reason) => reason !== value)
    );
  };

  return (
    <>
      <div
        className="flex flex-col"
        style={{ height: componentHeight }}
      >
        <Header />
        <div className="flex flex-row flex-1 overflow-hidden font-Poppins">
          <div className="flex-1">
            <div className="h-full overflow-y-auto relative">
              <button
                onClick={() => history.goBack()}
                className="absolute top-6 right-6 cursor-pointer z-10"
              >
                <img src="/images/cross.svg" alt="Close" className="w-5 h-5" />
              </button>
              <div className="w-429px flex flex-col py-8 mx-auto">
                <div className="pb-4">
                  <h1 className="text-ft6 font-semibold">Cancel Zbrain Plan</h1>
                </div>
                <div className="mb-4 font-normal text-ft2">
                  We're sorry to see you cancel your plan. To help improve, we have a few short questions for you before you leave us.
                </div>
                <div className="mb-4 font-medium text-ft2">
                  Why are you cancelling your plan?
                </div>
                <div className="mb-6 text-ft13 font-PoppinsRegular">
                  {[
                    { id: "missing", text: "Features I need are missing." },
                    { id: "technical", text: "Too technical to set up and manage." },
                    { id: "expensive", text: "Too expensive" },
                    { id: "shifted", text: "I have shifted to a new application." },
                  ].map((reason) => (
                    <div className="flex items-center mb-3" key={reason.id}>
                      <input
                        type="checkbox"
                        id={reason.id}
                        name="reason"
                        value={reason.id}
                        className="mr-2"
                        checked={cancelReason.includes(reason.id)}
                        onChange={handleReasonChange}
                      />
                      <label htmlFor={reason.id}>{reason.text}</label>
                    </div>
                  ))}
                </div>
                <div className="mb-4">
                  <div className="text-ft2 font-normal text-grey-69727B mb-2">Type <span className="font-medium text-black-0">'CANCEL'</span> and press confirm to continue.</div>
                  <input
                    type="text"
                    value={cancelText}
                    onChange={(e) => setCancelText(e.target.value)}
                    className="w-full p-2 border border-grey-300 rounded"
                    placeholder="Type CANCEL"
                  />
                </div>
                <div className="flex justify-center gap-5">
                  <button
                    disabled={isCancelationLoading || cancelText !== "CANCEL" || !cancelReason}
                    onClick={cancelSubscriptionPlan}
                    className={`${isCancelationLoading || cancelText !== "CANCEL" || !cancelReason ? "cursor-default opacity-40" : "cursor-pointer"
                      } h-10 w-full outline-none flex justify-center items-center py-11px px-4 md:text-ft13 text-ft1_5 font-PoppinsRegular font-medium rounded-md bg-red-F64D4A text-white`}
                  >
                    {isCancelationLoading ? (
                      <BiLoaderAlt className="animate-spin mx-auto w-6 h-6 text-white" />
                    ) : (
                      "Cancel Plan"
                    )}
                  </button>
                </div>
                <div className="text-center text-ft1_5 text-grey-737373 mt-4">
                  By cancelling this plan, I agree to ZBrain's{" "}
                  {/* <a href="#" className="text-blue-500 hover:underline"> */}
                  cancellation policy
                  {/* </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancelPlan;